// Libraries
import React, { useEffect, useState } from "react";
import { Typography, Input, Row, Col, List, Tag, Button, Alert } from "antd";

// Components
import Layout from "../../../components/layout/Layout";
import Upload from "../../../components/upload/Upload";
import MyButton from "../../../components/mybutton/MyButton";
import PdfFileLists from "../../../components/pdffilelists/PdfFileLists";

// Style
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../../hooks/useToken";
import userActions from "../../../redux-toolkit/actions/user/user.action";
import { useNavigate, useParams } from "react-router-dom";
import interceptor from "../../../shared/interceptor";

const { Title, Text } = Typography;
function SubscriptionUser() {

  // const userCredits = useSelector((state) => state.userReducer.userCredits)
  const [userCredits, setUserCredits] = useState()
  const dispatch = useDispatch()
  const { token, userID } = useToken()
  const { id } = useParams()
  const [count, setCount] = useState(0)
  const [loading, setTLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [items, setItems] = useState([])
  const naviagte = useNavigate();
  const [message, setMessage] = useState(null)
  var creditChk = 0
  const [packages, setPackage] = useState(id == 1 ? 'Adult Use (Basic)' :
    id == 2 ? 'Therapeutic (Basic)' :
      id == 3 ? 'Therapeutic & Adult Use (Basic)' :
        id == 4 ? 'Therapeutic & Adult Use (Advanced)' :
          id == 5 ? 'Therapeutic & Adult Use (Complete)' :
            id == 6 ? '6 Adult Use Effects' :
              id == 7 ? '6 Therapeutic Effects' :
                id == 8 ? '12 Combined Effects' :
                  '')
  const [credit, setCredit] = useState(null)
  const [availcredit, setAvailCredit] = useState(credit)
  const [errorFile, setErrorFile] = useState([])

  const uploadedFile = (value, status) => {
    if (status) {
      if (credit != creditChk) {
        creditChk = creditChk + 1
        setItems(oldArray => [...oldArray, value]);
        setCredit(credit - 1)
      }
    }
    else {
      setErrorFile(oldfile => [...oldfile, value]);
    }
  }

  useEffect(() => {
    if (!userCredits) {
      userActions.getUserCredits(token, userID).then(res => {
        setUserCredits(res)
        setCredit(id == 1 ? res?.recreational_basic_credits :
          id == 2 ? res?.medical_basic_credits :
            id == 3 ? res?.medical_and_recreational_basic :
              id == 4 ? res?.medical_and_recreational_advanced :
                id == 5 ? res?.medical_and_recreational_complete :
                  id == 6 ? res?.package_6 :
                    id == 7 ? res?.package_7 :
                      id == 8 ? res?.package_8 :
                        null
        )
      })
    }
  })

  useEffect(() => {
    setCredit(id == 1 ? userCredits?.recreational_basic_credits :
      id == 2 ? userCredits?.medical_basic_credits :
        id == 3 ? userCredits?.medical_and_recreational_basic :
          id == 4 ? userCredits?.medical_and_recreational_advanced :
            id == 5 ? userCredits?.medical_and_recreational_complete :
              id == 6 ? userCredits?.package_6 :
                id == 7 ? userCredits?.package_7 :
                  id == 8 ? userCredits?.package_8 :
                    null
    )
    setAvailCredit(id == 1 ? userCredits?.recreational_basic_credits :
      id == 2 ? userCredits?.medical_basic_credits :
        id == 3 ? userCredits?.medical_and_recreational_basic :
          id == 4 ? userCredits?.medical_and_recreational_advanced :
            id == 5 ? userCredits?.medical_and_recreational_complete :
              id == 6 ? userCredits?.package_6 :
                id == 7 ? userCredits?.package_7 :
                  id == 8 ? userCredits?.package_8 :
                    null
    )
  }, [userCredits])

  useEffect(() => {
    console.log(items, availcredit)
    if (items.length)
      setCredit(availcredit - items.length)
  }, [items, credit])

  const removeItem = (value) => {
    setItems(items.filter(x => x.id != value));
  }

  const userOrder = () => {
    if (items.length > 0 && title) {
      setTLoading(true)

      let model = {
        "files": items.map(x => x.id),
        "name": title,
        "package_selected": packages
      }
      interceptor.axiosPost('Post', 'order/', model, token).then((res) => {
        if (res.status === 200) {
          setTLoading(false)
          naviagte(`/order-submitted/${res.data.data.id}`);

        }
        else {
          console.log("error getting user : ", res)
        }
      }).catch((err) => {
        return err
      })
    } else {
      setMessage(!title ? 'Please Select Order Title' : 'Please upload file')
    }

  }
  return (
    <Layout title="New Analysis Order">
      <div className="analysis-order">
        <Row>
          <Col xl={6} lg={12} md={12} sm={16} xs={24}>
            <Title level={5}>Analysis Order Title</Title>
            <Input placeholder="Input your title here" onKeyUp={(e) => {
              setTitle(e.target.value)
            }} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" xl={10} lg={16} md={24} sm={24} xs={24}>
            <Title style={{ textAlign: 'left' }} level={4}>Upload Your COA PDF Files</Title>
            <Upload uploadedFile={uploadedFile} credit={credit} availCredit={availcredit} accept={'.pdf'} />
            <div className="analysis-list">

              <List
                bordered
                footer={
                  <Button
                    className="my-button"
                    loading={loading}
                    style={{ backgroundColor: "#609BE5", color: "#fff", width: "initial", display: "inline-block" }}
                    htmlType="submit"
                    onClick={userOrder}
                  >
                    Start Analysis
                  </Button>

                }
              >
                <List.Item>
                  <Tag color="#1C69C2"></Tag>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>{packages}  </Text>
                    <Text>COAs Analyzed: {items.length}</Text>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Typography>Credits Used</Typography>
                    <Typography>{items.length}</Typography>
                  </div>
                </List.Item>
                <List.Item>
                  <Tag color="#C4C4C4"></Tag>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Credits Left After Order: {credit}</Text>
                    {/* <Text>COAs Analyzed: 7</Text> */}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Typography>


                    </Typography>
                  </div>
                </List.Item>
              </List>
              {message ? <Alert type='error' message={message} /> : ''}

            </div>
          </Col>
          {/* <Col className="gutter-row" xl={10} lg={16} md={24} sm={24} xs={24}>
            <PdfFileLists showButton={true} showTag={false} count={count} items={items} onRemove={removeItem} />
          </Col> */}

          <Col className="gutter-row" xl={10} lg={16} md={24} sm={24} xs={24}>
            {errorFile.length > 0 && <PdfFileLists showButton={false} showtag={false} count={count} title={'Rejected Files'} items={errorFile} onRemove={removeItem} />}
            <PdfFileLists showButton={true} showtag={false} count={count} items={items} onRemove={removeItem} />
          </Col>
        </Row>
      </div>
    </Layout >
  );
}

export default SubscriptionUser;
