import React, { createElement, useEffect, useRef } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Checkbox, Col, List, Row, Select, Spin, Typography, Switch } from "antd";

// Style
import "./style.css";
import { Dashboard } from "../../icons/Icons";
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
const { Title, Text } = Typography;

const onSearch = (value) => {
  console.log('search:', value);
};

function StackedChart(props) {
  const { item, change, title } = props
  const [state, setState] = useState()
  const [active, setActive] = useState(false)
  const [filter, setFilter] = useState(props.item)
  const [line, setLine] = useState('')
  const [char, setChar] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [selected, setSeletected] = useState('')

  const [options, setoptions] = useState('')
  const [scrolledoptions, setScrolledoptions] = useState('')
  let mouseMoveTimer
  const strains_list = item?.strains_list
  const onChange = (value) => {

    console.log(`selected ${value}`);
    change(value)
    setSeletected(value)
    setLine(value)
    window.scrollTo({
      top: 500,
      behavior: 'smooth' // Optional: Adds smooth scrolling animation
    });
  };

  const foo2 = (value) => {
    console.log(`foo2 ${value}`);

  };

  function foo() {
    console.log(`foo`);
  }
  useEffect(() => { }, [selected])
  useEffect(() => { }, [scrolledoptions])
  const chartRef = useRef(null);


  useEffect(() => {

    const handleTouchEnd = (event) => {
      const chart = chartRef.current?.chart;
      if (chart) {
        const plotBackground = chart.container.querySelector('.highcharts-series-group');
        const isTouchInPlotArea = plotBackground && plotBackground.contains(event.target);
        if (isTouchInPlotArea) {
          // Handle touchend only if it occurred within the plot background area
          const touchX = event.changedTouches[0].clientX;
          const chartPosition = chart.container.getBoundingClientRect();
          const touchRelativeX = touchX - chartPosition.left;
          const xAxis = chart.xAxis[0]; // Assuming x-axis is the first axis in your chart
          const xValue = xAxis.toValue(touchRelativeX);
          if (xValue > -1) {
            // Handle the touch event based on the xValue
            let claue = Math.ceil(xValue);
            change(claue + 1);
            setSeletected(strains_list[claue]);
          }
        }
      }
    };
    // document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      // document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [item])


  useEffect(() => {

    // if (item?.strains_list.find(x => x.length > 22)) {
    //   setChar(true)
    // }
    // else {
    //   setChar(false)
    // }

    if (item) {

      setCharOption()

      item.line_graph_data.forEach((element, ind) => {
        element.color = item.line_graph_color_list[ind]
        element.data = element.data.map(j => j.y)
      });

    }
  }, [item])

  const setCharOption = (scrolled) => {

    setoptions({
      stacked: true,
      chart: {
        type: 'area',
      },

      title: {
        text: null // Set title text to null to remove the chart title
      },
      legend: {
        enabled: false
      },
      yAxis: {
        title: {
          text: null // Set y-axis title to null to remove it
        },
        labels: {
          enabled: true // Disable labels on the y-axis
        }
        // title: {
        //   useHTML: true,
        //   text: 'Million tonnes CO<sub>2</sub>-equivalents'
        // }
      },
      xAxis: {
        categories: item?.strains_list,//item?.strains_list.map(x => x.length > 18 && x.length > 25 ? (x.substring(0, 17) + '...' + x.substring(x.length - 5)) : x),
        labels: {
          rotation: 310,
          style: {
            color: '#558AC9',
            fontSize: '10px',
          }
        },

      },
      tooltip: {
        visible: true,
        enabled: true,
        shared: true,
        headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineWidth: 5,
          pointWidth: 30,
          events: {
            click: function (event) {
              change(event.point.index + 1)
              setSeletected(strains_list[event.point.index])
            },

          }
        }
      },
      series: item.line_graph_data,
      responsive: {
        rules: [{
          condition: {
            maxWidth: 768 // Define the breakpoint for mobile devices (e.g., 768px)
          },
          chart: {
            type: 'area',
            xAxis: {
              min: 0,
              max: !scrolled ? item?.strains_list.length - 1 : 30,
              scrollbar: {
                enabled: scrolled ? true : false
              },
              tickLength: 0,

            },
          }
        }]
      }

    });
  }


  const effectChange = (index, value, selected, unSelect, scrolled) => {
    if (selected && !unSelect) {
      item.line_graph_data.forEach(x => x.checked = false)
    }
    else if (!selected && unSelect) {
      item.line_graph_data.forEach(x => x.checked = true)
    }
    else {
      item.line_graph_data[index].checked = !value
    }
    let finder = item.line_graph_data.filter(x => !x.checked)
    let color = finder.map(x => x.color)

    debugger
    if (!scrolled) {

      setoptions({
        stacked: true,
        chart: {
          type: 'area',
        },

        title: {
          text: null // Set title text to null to remove the chart title
        },
        legend: {
          enabled: false
        },
        yAxis: {
          title: {
            text: null // Set y-axis title to null to remove it
          },
          labels: {
            enabled: true // Disable labels on the y-axis
          }
          // title: {
          //   useHTML: true,
          //   text: 'Million tonnes CO<sub>2</sub>-equivalents'
          // }
        },
        xAxis: {
          categories: item?.strains_list,//item?.strains_list.map(x => x.length > 18 && x.length > 25 ? (x.substring(0, 17) + '...' + x.substring(x.length - 5)) : x),
          min: 0,
          max: !scrolled ? item?.strains_list.length - 1 : 30,
          scrollbar: {
            enabled: scrolled ? true : false
          },
          tickLength: 0,
          labels: {
            rotation: 310,
            style: {
              color: '#558AC9',
              fontSize: '10px',
            }
          },

        },
        tooltip: {
          visible: true,
          enabled: true,
          shared: true,
          headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
          positioner: function (labelWidth, labelHeight, point) {
            return {
              x: Math.max(
                0,
                Math.min(
                  point.plotX + this.chart.plotLeft - labelWidth / 2,
                  this.chart.chartWidth - labelWidth
                )
              ),
              y: 0
            };
          }
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineWidth: 5,
            events: {
              click: function (event) {
                console.log('Clicked on series:', this.name);
                console.log('Point data:', event.point.index);
                change(event.point.index + 1)
                setSeletected(strains_list[event.point.index])
              },

            }
          }
        },
        series: finder,
        responsive: {
          rules: [{
            condition: {
              maxWidth: 768 // Define the breakpoint for mobile devices (e.g., 768px)
            },
            chart: {
              type: 'area',
              xAxis: {
                min: 0,
                max: !scrolled ? item?.strains_list.length - 1 : 30,
                scrollbar: {
                  enabled: scrolled ? true : false
                },
                tickLength: 0,

              },
            }
          }]
        }

      });
    } else {

      setScrolledoptions({
        stacked: true,
        chart: {
          type: 'area',
        },

        title: {
          text: null // Set title text to null to remove the chart title
        },
        legend: {
          enabled: false
        },
        yAxis: {
          title: {
            text: null // Set y-axis title to null to remove it
          },
          labels: {
            enabled: true // Disable labels on the y-axis
          }
          // title: {
          //   useHTML: true,
          //   text: 'Million tonnes CO<sub>2</sub>-equivalents'
          // }
        },
        xAxis: {
          categories: item?.strains_list,//item?.strains_list.map(x => x.length > 18 && x.length > 25 ? (x.substring(0, 17) + '...' + x.substring(x.length - 5)) : x),
          min: 0,
          max: 30,
          scrollbar: {
            enabled: true
          },
          labels: {
            rotation: 310,
            style: {
              color: '#558AC9',
              fontSize: '10px',
            }
          },

        },
        tooltip: {
          visible: true,
          enabled: true,
          shared: true,
          headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineWidth: 5,
            pointWidth: 30,
            events: {
              click: function (event) {
                change(event.point.index + 1)
                setSeletected(strains_list[event.point.index])
              },

            }
          }
        },
        series: finder,

      });
    }
  }


  const click = () => {

    setActive(!active)
    let id = document.getElementById("sidebar")
    if (id.classList.contains('active')) {
      document.getElementById("sidebarClick").click();
    }
  }

  return (
    <>
      <div className="stacked-chart">
        {props.showTitle ? (
          <Title level={5}>Order #{title} </Title>
        ) : null}
        <Row gutter={16} >
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: '0px' }}>
            <div className="jump-to" style={{ display: 'flex', }}>
              <label>Jump to:</label>
              <div className="jum-strain" >
                <Select
                  showSearch
                  placeholder="Jump to"
                  optionFilterProp="children"
                  onChange={value => onChange(value)}
                  onSearch={onSearch}
                  value={selected}
                  style={{ width: '100%' }}
                >
                  {item?.strains_list.map((x, index) => (
                    <Select.Option key={index + 1}   >
                      {x}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} style={{ marginBottom: '0px' }}>
            <div style={{ textAlign: 'left' }}>
              <div id="activebarClick" className={`sidebar-stacked`} onClick={click}>
                <Text className="">  <Dashboard color="#ffff" /> </Text>
                <Text className="effect">Filter Effects</Text>
              </div>
            </div>
            <aside id="activebar" className={`${active ? "activebar" : "inActive"}`} >
              <div className="legend-sidebar">
                <div>
                  <Button className="effectClass" onClick={() => effectChange(null, null, true, false, scroll)} >Select All</Button>
                  <Button className="effectClass" onClick={() => effectChange(null, null, false, true, scroll)}> Deselect All </Button>
                  <Button className="sidebar-stacked" style={{ float: 'right', paddingRight: '6px', paddingLeft: '6px', top: '0px', right: '0px' }} onClick={click}> X </Button>

                </div>
                <div className="legends">
                  {item?.line_graph_data?.map((x, index) => (
                    <div style={{ float: 'left' }}>
                      <Checkbox checked={!x.checked} onChange={(e) => {
                        console.log(e.target.checked)
                        effectChange(index, e.target.checked, false, false, scroll)
                      }}>
                        <Text className="legendcolor" style={{ background: item.line_graph_color_list[index] }}></Text>

                        <Text className="legendText">{x.name}</Text>

                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
            </aside >
          </Col>
        </Row>


        <Row gutter={16} style={{ marginBottom: '0px' }}>
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginBottom: '0px' }}>
            <div className="stacked" style={{ marginTop: '0px' }}>
              <Switch checkedChildren="Scroll ON" unCheckedChildren="Scroll Off" onChange={(e) => {
                setScroll(e)
                debugger

                effectChange(null, null, true, false, (e))

              }
              }
              />

              {options ?
                <div id="chart" className="stackCharOverflow">
                  {/* <ReactApexChart
                    options={state?.options}
                    series={state?.series}
                    type="area"
                    height={state?.options?.chart?.height}

                  /> */}
                  {scroll ?
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={scrolledoptions}
                      ref={chartRef}
                    /> :
                    <>
                      <Text className="legendText"></Text>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartRef}
                      />
                    </>
                  }
                </div>
                :
                <div style={{ textAlign: "center" }}>
                  <Spin></Spin>
                </div>
              }
            </div>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default StackedChart;
